import { firestore } from "@/firebase";
import firebase from "firebase";
import * as Sentry from "@sentry/nextjs";
import { User } from "@/models/user";

const addUser = async (data: {
  userId: string;
  userData: User;
}): Promise<void> => {
  try {
    const { userId, userData } = data;

    await firestore
      .collection("users")
      .doc(userId)
      .set(userData, { merge: true });
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

export default addUser;
