import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "@/redux/index";
import { auth, firestore, GoogleAuthProvider } from "@/firebase";
import * as Sentry from "@sentry/nextjs";
import moment from "moment";
import { User } from "@/models/user";
import { resetUser, userSelectors } from "./user";
import { resetSurvey } from "./survey";
import addUser from "@/db/user/addUser";
import { resetSession } from "./session";
import getUser from "@/db/user/getUser";
import getPrimaryWorkspace from "@/db/user/getPrimaryWorkspace";
import heap from "@/lib/heap";

const AUTH_INIT = "auth/AUTH_INIT";
const AUTH_SUCCESS = "auth/AUTH_SUCCESS";
const AUTH_FAILURE = "auth/AUTH_FAILURE";
const RESET_AUTH = "auth/RESET_AUTH";

const authState = {
  uid: auth.currentUser ? auth.currentUser.uid : (null as string | null),
  isAuthenticating: false as boolean,
  errorAuthenticating: null as Error | null,
};

export default function reducer(
  state = authState,
  action: AuthAction
): typeof authState {
  let newState: typeof authState;
  switch (action.type) {
    case AUTH_INIT:
      newState = {
        ...state,
        isAuthenticating: true,
      };

      return newState;

    case AUTH_SUCCESS:
      newState = {
        ...state,
        uid: action.uid,
        isAuthenticating: false,
      };

      return newState;

    case AUTH_FAILURE:
      return {
        ...state,
        isAuthenticating: false,
        errorAuthenticating: action.error,
      };
    case RESET_AUTH:
      return authState;
    default:
      return state;
  }
}

export const authInit = (
  next?: string
): { type: typeof AUTH_INIT; next?: string } => {
  return { type: AUTH_INIT, next };
};

export const authSuccess = (
  uid: string
): {
  type: typeof AUTH_SUCCESS;
  uid: string;
} => {
  return { type: AUTH_SUCCESS, uid };
};

export const authFailure = (
  error: Error
): { type: typeof AUTH_FAILURE; error: Error } => {
  return { type: AUTH_FAILURE, error };
};

export const resetAuth = (): { type: typeof RESET_AUTH } => {
  return { type: RESET_AUTH };
};

export const WatchAuth =
  (): ThunkAction<() => void, RootState, null, Action<string>> =>
  (dispatch, getState) => {
    try {
      return auth.onAuthStateChanged(function (user) {
        if (user) {
          heap.identify(user.uid);
          dispatch(authSuccess(user.uid));
        }
      });
    } catch (error) {
      dispatch(authFailure(error));
      Sentry.captureException(error);
    }
  };

export const EmailAuthStart =
  (
    email: string,
    next?: string
  ): ThunkAction<Promise<void>, RootState, null, Action<string>> =>
  async (dispatch, getState) => {
    try {
      dispatch(authInit());
      const actionCodeSettings = {
        url: `${window.location.protocol}//${window.location.host}/auth`,
        // This must be true.
        handleCodeInApp: true,
      };

      await auth.sendSignInLinkToEmail(email, actionCodeSettings);

      window.localStorage.setItem("emailForSignIn", email);
      console.log(next, !!next);
      if (!!next) window.localStorage.setItem("authenticatingNext", next);
      dispatch(authSuccess(null));
    } catch (error) {
      dispatch(authFailure(error));
      Sentry.captureException(error);
      throw error;
    }
  };

export const EmailAuthComplete =
  (
    callback?: (workspace: string) => void
  ): ThunkAction<Promise<void>, RootState, null, Action<string>> =>
  async (dispatch, getState) => {
    try {
      dispatch(authInit());

      if (!auth.isSignInWithEmailLink(window.location.href))
        throw new Error("Invalid link");

      var next = window.localStorage.getItem("authenticatingNext");
      var email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }
      const result = await auth.signInWithEmailLink(
        email,
        window.location.href
      );

      console.log(email, next);

      const uid = result.user.uid;
      const user = result.user;

      // add user to db if they don't exist
      const userEntity = await getUser(uid)();
      if (!userEntity) {
        // user doesn't exist -> add
        const newUser: User = {
          id: uid,
          name: user.displayName,
          image: user.photoURL,
          joinedTime: moment().unix(),
          email: result.user.email,
        };

        await addUser({ userId: uid, userData: newUser });
        dispatch(authSuccess(uid));

        if (callback) {
          if (next) callback(next);
          else callback(`/access`);
        }
      } else {
        dispatch(authSuccess(uid));

        if (callback) {
          const workspace = await getPrimaryWorkspace({
            uid,
            userData: userEntity,
          });
          if (next) callback(next);
          else callback(workspace || "/access");
        }
      }
      window.localStorage.removeItem("authenticatingNext");
      window.localStorage.removeItem("emailForSignIn");
    } catch (error) {
      dispatch(authFailure(error));
      Sentry.captureException(error);
      throw error;
    }
  };

export const GoogleAuth =
  (
    callback?: (workspace: string, userId?: string, userEmail?: string) => void,
    next?: string
  ): ThunkAction<Promise<void>, RootState, null, Action<string>> =>
  async (dispatch, getState) => {
    try {
      dispatch(authInit());

      const result = await auth.signInWithPopup(GoogleAuthProvider);

      const uid = result.user.uid;
      const user = result.user;

      // add users to db if they don't exist
      const userEntity = await getUser(uid)();
      if (!userEntity) {
        // user doesn't exist
        const newUser: User = {
          id: uid,
          name: user.displayName,
          image: user.photoURL,
          joinedTime: moment().unix(),
          email: result.user.email,
        };

        await addUser({ userId: uid, userData: newUser });
        dispatch(authSuccess(uid));
        if (callback) {
          if (next) callback(next, uid, result.user.email);
          else callback(null, uid, result.user.email);
        }
      } else {
        dispatch(authSuccess(uid));
        if (callback) {
          if (next) callback(next, uid, result.user.email);
          else
            callback(
              await getPrimaryWorkspace({ uid, userData: userEntity }),
              uid
            );
        }
      }
    } catch (error) {
      dispatch(authFailure(error));
      Sentry.captureException(error);
    }
  };

export const LogOut =
  (
    callback: () => void
  ): ThunkAction<Promise<void>, RootState, null, Action<string>> =>
  async (dispatch, getState) => {
    try {
      await auth.signOut();
      dispatch(resetAuth());
      dispatch(resetUser());
      dispatch(resetSurvey());
      dispatch(resetSession());
      callback();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

type AuthAction =
  | ReturnType<typeof authInit>
  | ReturnType<typeof authSuccess>
  | ReturnType<typeof authFailure>
  | ReturnType<typeof resetAuth>;

const isAuthenticating = (state: RootState) => state.auth.isAuthenticating;
const errorAuthenticating = (state: RootState) =>
  state.auth.errorAuthenticating;
const uid = (state: RootState) => state.auth.uid;
const loggedIn = (state: RootState) => Boolean(state.auth.uid);

export const authSelectors = {
  isAuthenticating,
  errorAuthenticating,
  uid,
  loggedIn,
};
