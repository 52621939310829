import { combineReducers } from "redux";
import auth from "./auth";
import user from "./user";
import session from "./session";
import survey from "./survey";
import { reducer as formReducer } from "redux-form";
import { routerReducer } from "connected-next-router";

export const RESET_ACTION = {
  type: "RESET",
};

const rootReducer = {
  auth,
  user,
  survey,
  session,
  form: formReducer,
  router: routerReducer,
};

const appReducer = combineReducers(rootReducer);

export const combinedReducer = (state, action) => {
  if (action.type === "RESET") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof combinedReducer>;

export default rootReducer;
