import { firestore } from "@/firebase";
import { v4 as uuidv4 } from "uuid";
import { Session } from "@/models/session";
import * as Sentry from "@sentry/nextjs";
import { Group } from "@/models/group";

const getSessionById =
  (sessionId: string) => async (): Promise<Session | null> => {
    try {
      if (!sessionId) return null;
      // see if session already exists
      const sessionDoc = await firestore
        .collection("sessions")
        .doc(sessionId)
        .get();
      if (sessionDoc.exists) return sessionDoc.data() as Session;
      else return null;
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

export default getSessionById;
