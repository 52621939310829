import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "@/redux";
import { Session } from "@/models/session";
import { createSelector } from "reselect";
import * as Sentry from "@sentry/nextjs";
import { firestore } from "@/firebase";
import { authSelectors } from "./auth";

const WATCH_SESSION_INIT = "session/WATCH_SESSION_INIT";
const WATCH_SESSION_SUCCESS = "session/WATCH_SESSION_SUCCESS";
const WATCH_SESSION_FAILURE = "session/WATCH_SESSION_FAILURE";
const RESET_SESSION = "session/RESET_SESSION";

const sessionState = {
  session: null as Session | null,
  isWatching: false as boolean,
  error: null as Error | null,
};

export default function reducer(
  state = sessionState,
  action: SessionAction
): typeof sessionState {
  switch (action.type) {
    case WATCH_SESSION_INIT:
      return {
        ...state,
        isWatching: false,
        error: null,
      };
    case WATCH_SESSION_SUCCESS:
      return {
        ...state,
        isWatching: true,
        session: action.session,
      };
    case WATCH_SESSION_FAILURE:
      return {
        ...state,
        isWatching: false,
        error: action.error,
      };
    case RESET_SESSION:
      return sessionState;
    default:
      return state;
  }
}

export const watchSessionInit = (): { type: typeof WATCH_SESSION_INIT } => {
  return { type: WATCH_SESSION_INIT };
};

export const watchSessionSuccess = (
  session: Session
): { type: typeof WATCH_SESSION_SUCCESS; session: Session } => {
  return { type: WATCH_SESSION_SUCCESS, session };
};

export const watchSessionFailure = (
  error: Error
): { type: typeof WATCH_SESSION_FAILURE; error: Error } => {
  return { type: WATCH_SESSION_FAILURE, error };
};

export const resetSession = (): { type: typeof RESET_SESSION } => {
  return { type: RESET_SESSION };
};

export const WatchSession =
  (
    sessionId: string
  ): ThunkAction<() => void, RootState, null, Action<string>> =>
  (dispatch, getState) => {
    try {
      dispatch(watchSessionInit());

      const state = getState();

      const uid = authSelectors.uid(state);
      if (!uid) throw new Error("No uid to watch session");

      return firestore
        .collection("sessions")
        .doc(sessionId)
        .onSnapshot(
          (snap) => {
            const session = snap.data() as Session;
            if (session) {
              dispatch(watchSessionSuccess(session));
            }
          },
          (e) => {
            throw e;
          }
        );
    } catch (error) {
      dispatch(watchSessionFailure(error));
      Sentry.captureException(error);
      console.log(error);

      return () => {};
    }
  };

type SessionAction =
  | ReturnType<typeof watchSessionInit>
  | ReturnType<typeof watchSessionSuccess>
  | ReturnType<typeof watchSessionFailure>
  | ReturnType<typeof resetSession>;

const errorWatchingSession = (state: RootState) => state.session.error;
const session = (state: RootState) => state.session.session;
const sessionStepIndex = createSelector(
  session,
  (sessionVal) =>
    sessionVal &&
    sessionVal.steps &&
    sessionVal.steps.filter((s) => !!s.completedTime).length
);
const sessionStepComplete = (stepIndex: number) =>
  createSelector(session, (sessionVal) =>
    sessionVal &&
    sessionVal.steps &&
    sessionVal.steps.length > 0 &&
    sessionVal.steps[stepIndex] &&
    sessionVal.steps[stepIndex].completedTime
      ? true
      : false
  );
const sessionComplete = createSelector(
  session,
  (sessionVal) => sessionVal && !!sessionVal.completedTime
);
const sessionGroupId = createSelector(session, (sessionVal) =>
  sessionVal ? sessionVal.groupId : null
);

export const sessionSelectors = {
  session,
  sessionStepIndex,
  errorWatchingSession,
  sessionStepComplete,
  sessionComplete,
  sessionGroupId,
};
