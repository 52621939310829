import getFolder from "@/db/folder/getFolder";
import { Folder } from "@/models/folder";
import { Group } from "@/models/group";
import { Org } from "@/models/org";
import { Owner } from "@/models/owner";
import { Program } from "@/models/program";
import { ownerDomain } from "./owner";

/*
  takes org + additional path + group and returns appropriate path given current domain
  eg. steppable.com -> paths should include org slug (eg. steppable.com/brainhealthbootcamp/guided-program)
  eg. brainhealthbootcamp.steppable.com -> paths should not include org slug (eg. brainhealthbootcamp.steppable.com/guided-program)
  eg. learn.brainhealthbootcamp.org -> paths should not include org slug (eg. learn.brainhealthbootcamp.org/guided-program)
*/

export const ownerPath = (
  owner: Owner,
  path: string,
  params?: object | null
): string => {
  let p: string;
  if (
    process.browser &&
    (location.hostname.includes(`${owner?.slug}.`) ||
      location.hostname.includes(ownerDomain(owner, true)))
  ) {
    p = path ? `/${path}` : `/`;
  } else {
    p = path
      ? `/${owner?.slug || owner?.id}/${path}`
      : `/${owner?.slug || owner?.id}`;
  }
  if (params === null) return p;
  if (typeof params === "undefined") return p + (window.location.search || "");
  let newParams = new URLSearchParams(window.location.search);
  for (const key in params) {
    if (typeof params[key] !== "undefined") {
      newParams.set(key, params[key]);
    }
    if (params[key] === null) {
      newParams.delete(key);
    }
  }
  if (newParams.toString() === "") return p;
  return p + "?" + newParams.toString();
};

/*
  Takes org, path, and group and returns appropriate path given current domain
*/
export const orgGroupPath = (
  org: Org,
  group: Group,
  path: string,
  params?: object | null
): string => {
  let p: string;
  if (
    process.browser &&
    (location.hostname.includes(`${org.slug}.`) ||
      location.hostname.includes(org.domain))
  ) {
    p = path ? `/group/${group?.id}/${path}` : `/group/${group?.id}`;
  } else {
    p = path
      ? `/${org.slug}/group/${group?.id}/${path}`
      : `/${org.slug}/group/${group?.id}`;
  }
  if (params === null) return p;
  if (typeof params === "undefined") return p + (window.location.search || "");
  let newParams = new URLSearchParams(window.location.search);
  for (const key in params) {
    if (params[key]) {
      newParams.set(key, params[key]);
    }
    if (params[key] === null) {
      newParams.delete(key);
    }
  }
  if (newParams.toString() === "") return p;
  return p + "?" + newParams.toString();
};

/*
  takes org + program + group + options and returns a url
  eg. if custom domain learn.brainhealthbootcamp.org setup & requesting link for intro program -> https://learn.brainhealthbootcamp.org/intro
  eg. if no custom domain & requesting link for brainhealthbootcamp intro program -> https://brainhealthbootcamp.steppable.com/intro
*/
export const programPath = (
  owner: Owner,
  program: Program,
  group?: Group,
  pretty?: boolean
): string => {
  const prefix = pretty ? "" : "https://";
  const groupSection = group ? `${group?.slug}` : "";
  if ((owner as Org)?.domain) {
    return `${prefix}${(owner as Org).domain}/${program.slug}/${groupSection}`;
  } else if ((owner as Org)?.slug) {
    return `${prefix}${(owner as Org).slug}.steppable.com/${
      program.slug
    }/${groupSection}`;
  } else {
    return `${prefix}steppable.com/${owner.id}/${program.slug}/${groupSection}`;
  }
};

/*
  takes owner & path and returns appendable pretty path
  eg. "/[long-folder-id]" -> "/parent-folder-slug/folder-slug"
*/
export const expandPath = async (
  owner: Owner,
  path: string,
  type: "courses" | "customers",
  admin?: boolean
): Promise<string> => {
  if (path === "/") return "";

  if (type === "courses") {
    // function that takes a path and returns the parent folder
    const getParentFolder = async (path: string): Promise<Folder> => {
      const parentFolderId = path.split("/")[1];
      // get parent folder
      if (parentFolderId) {
        return await getFolder(owner, parentFolderId, admin)();
      } else return null;
    };

    // recursively get all parent folders and append to path
    let folder = await getParentFolder(path);
    let expandedPath = "";
    while (folder !== null) {
      expandedPath =
        expandedPath !== "" ? folder.slug + "/" + expandedPath : folder.slug;
      folder = await getParentFolder(folder.path);
    }
    expandedPath = "/" + expandedPath;

    return expandedPath;
  }
  // else if (type === "customers") {
  //   const parentGroupId = path.split("/")[1];
  // }
};
