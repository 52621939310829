import * as firebaseClient from "@/firebase";
import * as Sentry from "@sentry/nextjs";
import { User } from "@/models/user";

const getUser = (userId: string) => async (): Promise<User | null> => {
  try {
    if (!userId) return null;
    const userDoc = await firebaseClient.firestore
      .collection("users")
      .doc(userId)
      .get();

    if (userDoc.exists) return userDoc.data() as User;
    else return null;
  } catch (error) {
    Sentry.captureException(error);
  }
};

export default getUser;
