export const GOOGLE_ANALYTICS = "M06EYDDH44";
export const FIREBASE_API_KEY = "AIzaSyCyP8IhBVHlVjvfyG4Bf5FyTSOqtyVhrfo";
export const FIREBASE_PROJECT_ID = "avs-learn";
export const FIREBASE_STORAGE_BUCKET = "avs-learn.appspot.com";
export const FIREBASE_MESSAGING_SENDER_ID = "580463262244";
export const FIREBASE_APP_ID = "1:580463262244:web:69ba31a817f4eecc87b6b1";
export const FIREBASE_CLIENT_EMAIL =
  "firebase-adminsdk-5voq3@brainhealthbootcamp.iam.gserviceaccount.com";
export const SENTRY_DSN =
  "https://421e8aa8daa445e2a9e109465a3cf0b2@o950711.ingest.sentry.io/5899279";
export const ENCRYPTION_KEY = "qBK7DN7*!Xa38wyJ-cpv9o";
export const CAPTCHA_KEY = "6LdJgxAcAAAAAACftgeTLaY38eEQxITEywuCCpeZ";
export const SENDGRID_KEY =
  "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";
export const MAPBOX_TOKEN =
  "pk.eyJ1Ijoic3RlcHBhYmxlIiwiYSI6ImNreWRjeGpveTAyc2oyb2xoZTZ4OWRpZGsifQ.TwffWHayoqof4ES11fsmrA";
export const FIREBASE_SERVICE_ACCOUNT = {
  type: "service_account",
  project_id: "avs-learn",
  private_key_id: "e691a3276e67c6fbfc3e5a24c8401821fdb568bc",
  private_key:
    "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDWAggcOlJ2MxK/\nQdJw8QdCzWJyMZw1Mcph3T9Ex+aMaSyJv7FumSL5NFq9HcT96aB37XwoWK9cLYcs\nxE4yJ2H7G7TIRBJJM7O1sAQpEue7RS1yv9LbEU0pZPZGHXCFluLwzX1szrBNP7tB\nwYC7MYdrukar5IGkQs22g/vO3AR7Iqtnr/ff+en7nl+NLdO+lPQxIhmg8ZFTWwWg\nqZGWY+9tw4xC4z+ApGUwrBgISLX0GlNrqfD+61M56ANKQ74uYADL5OfFWyT5POEo\nD0w9B+ZYdm+30PMZcMSbaxDvOHDIHx2okJ+ac69gq6ywyC0VZSnwJhoOrxs1wt+U\npbvCnOM7AgMBAAECggEAGjY3fhWaogWRZ0GNn+21oPX899Wht1Y43pG1vRvq3gnH\nvbtv5Qb2pKVH89/gj+T8lZPN4Dc0i0q8Qrxsg3IVaycyllDYGIUvnrJHYCyDm4lW\njrZ2AgT1eXt3OpFCheo8M5hHo3sRVvTWX7Burv7bAlPXchhT1KWmApPZ1LVYCyiz\nlPs9Z/khcJw/9r+ucNPnaPh9LDcxai2zRl5tE+CBdgYMsoR4jLJJbgT0oCJH1guD\nFKqsUS1dNx3OmasNR86bMzZfUgGYVntgc3OiMojwacjfWzryW/VQc/2izpcE9G3k\ncajZb+wshW1qVgFTQGMoZ1W5nV8UEQMYAQ9RS0zI2QKBgQDr1vUEYLA+kn5c4sym\n3P+wDIbtZoSrfROund3skcpEUUxy0fw+IV03nlHcsyvk+sIpDtqEICMsJdlcKl+3\nXlEVO03ynsoWWyDE/tV03hCztKm+aCcbcfqBvU+4zN+tW2jBmEXKGF5fKdfU0fLd\n09sPN1mYic2mhVe5189vSk5IrQKBgQDoTVDE5UtYwP+W5rszj/WjJ4L2Pm6hvCe4\nWB35+DUZ20LOB4ZOOjltqmMTyHhhVyD0rpcSbaNN8JebkHtbhfnWzwREhMeErkGm\nQqxgwgTZchonmMBGnFeJJg2a6Nmv1BSS3neTbzcjETp6/IksoUyXNsUDSThI5eWd\nEu+semjQhwKBgFp9XgHR/43is618MD4OKeUoyVSps1oBYd3sIMy2m79e2lBsFbbZ\nImVGz+44qSzwsjMCygFMO3KjCyV79MHs5HubB6uCFdRCHu63QLvYwNvTBBZU7Dxx\nnUbOlpo3sdNYcXdmr9B9TSiCa5fy/pbyxOivuib3KPstyp06GxN+lU75AoGBAJMr\n6G/VlNa17zd7Fj6khcrHzYCULSLqQX/Y06T6SyoGb1x4B+6nlJqVyA9QgnOo74gU\n1EEolL1cH7C+6GgjiRU+MkX2mTDF9XGP7AWg5dGdKFTNwYL6qbqKETSDMObkReSG\nbNtLKnNdBt/D/mP4DL4ARPDAApisjbvls1JieuUhAoGAazv/rqAMs5ynaU+4Occq\n5ai52LL5gXX2VuN44CyoZLcW0EF63AF7R5epgkH0Z55hT/2WxnYpPR4hytTjIZeU\n7ie6sOBWQIP1qBU9VL9XAYeTVZu85Ds81MYrc1Zuu2gUx8oIGnplNp4/+W35D3p1\n9YA1PWxbOKLvdKmhe4Gy9KE=\n-----END PRIVATE KEY-----\n",
  client_email: "firebase-adminsdk-l7pgo@avs-learn.iam.gserviceaccount.com",
  client_id: "112224149955454732958",
  auth_uri: "https://accounts.google.com/o/oauth2/auth",
  token_uri: "https://oauth2.googleapis.com/token",
  auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
  client_x509_cert_url:
    "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-l7pgo%40avs-learn.iam.gserviceaccount.com",
};
export const SLACK_WEBHOOK_FEEDBACK =
  "https://hooks.slack.com/services/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";
export const STRIPE_TEST_SECRET_KEY =
  "sk_test_51K5LdKB3mNSZegYeZklYaK4RM4HK50W3dh4pKC8QSfoPFVVzSe5PhAerLf6JxttC3o2XHw4VubtXXoFw6RHZA4Hg00cizh0FiU";
export const STRIPE_LIVE_SECRET_KEY =
  "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";
export const STRIPE_TEST_PUBLISHABLE_KEY =
  "pk_test_51K5LdKB3mNSZegYexBoAAL7fm7EnYd1MkqnKr96z9tBPhmvozW3WsslNmhIF6ghS6ueHsH5g0p0umff4SyHO0ryk000F57TX30";
export const STRIPE_LIVE_PUBLISHABLE_KEY =
  "pk_live_51K5LdKB3mNSZegYeahWZquit6vFuMr1tx0uO2YIY9j8JWqg1npyNPekQEIrEsojnJjnanoIWzPRs4fxR8QNZnOjL0067QD2MhZ";
export const STRIPE_WEBHOOK_SECRET = "whsec_2tEOvCoarQkjvKg6L3kW2acOGDzSIbny";
export const POSTHOG_KEY = "phc_rVPveYY5NFlOi5IPz7fGZhPFoUYfX4mERnbbWSKBvFn";
export const SLACK_WEBHOOK_LOGS =
  "https://hooks.slack.com/services/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";
export const MUX_TOKEN_ID = "11102b6a-366e-4208-a580-a6511895cbfc";
export const MUX_TOKEN_SECRET =
  "hNDreYcmizqbtdwjIN9OrHYvgcRAVxD9TFvlxH5xjiNFkaIz01naEEJlO5aQ6XBqE3Ptf73iAP6";
export const OPENAI_API_KEY =
  "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";

