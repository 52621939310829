import * as firebaseClient from "@/firebase";
import * as Sentry from "@sentry/nextjs";
import { SurveyResponse } from "@/models/survey";

const getSurvey = async (data: {
  surveyId: string;
  sessionId?: string;
  userId: string;
  embeddedSessionId?: string;
  embeddedStepId?: string;
}): Promise<SurveyResponse | null> => {
  const { surveyId, userId, sessionId, embeddedSessionId, embeddedStepId } =
    data;
  try {
    console.log("SHOULD GET SURVEY", data);
    if (embeddedSessionId) {
      let embeddedSurveyQuery = await firebaseClient.firestore
        .collection("surveys")
        .where("surveyId", "==", surveyId)
        .where("embeddedSessionId", "==", embeddedSessionId)
        .where("embeddedStepId", "==", embeddedStepId)
        .where("userId", "==", userId)
        .get();

      if (!embeddedSurveyQuery.empty)
        return embeddedSurveyQuery.docs[0].data() as SurveyResponse;
    } else if (sessionId) {
      // see if survey exists
      const surveyQuery = await firebaseClient.firestore
        .collection("surveys")
        .where("surveyId", "==", surveyId)
        .where("sessionId", "==", sessionId)
        .where("userId", "==", userId)
        .get();
      if (!surveyQuery.empty)
        return surveyQuery.docs[0].data() as SurveyResponse;
    } else return null;
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

export default getSurvey;
