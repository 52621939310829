import * as firebaseClient from "@/firebase";
import { Org } from "@/models/org";
import * as Sentry from "@sentry/nextjs";

const getOrg = (orgId: string) => async (): Promise<Org> => {
  try {
    if (orgId) {
      const firestore = firebaseClient.firestore;
      const doc = await firestore.collection("orgs").doc(orgId).get();

      return doc.exists ? (doc.data() as Org) : null;
    } else return null;
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
};

export default getOrg;
