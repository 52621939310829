import { User } from "@/models/user";

const identify = (uid: string) => {
  try {
    (window as any).heap.identify(uid);
  } catch (error) {
    console.log(error);
  }
};

const addUserProperties = (properties: object) => {
  try {
    (window as any).heap.addUserProperties(properties);
  } catch (error) {
    console.log(error);
  }
};

const syncUser = (user: User) => {
  let userProperties: { [property: string]: any } = {};
  if (user.name) userProperties.name = user.name;
  if (user.email) userProperties.email = user.email;
  if (user.ownerships && user.ownerships.length > 0)
    userProperties.ownerships = user.ownerships;
  if (user.memberships && user.memberships.length > 0)
    userProperties.memberships = user.memberships;
  addUserProperties(userProperties);
};

export default {
  identify,
  addUserProperties,
  syncUser,
};
