// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { SENTRY_DSN } from "./env";

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://421e8aa8daa445e2a9e109465a3cf0b2@o950711.ingest.sentry.io/5899279",
  tracesSampleRate: 1.0,
});
