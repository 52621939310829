import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import "firebase/app-check";
import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  GOOGLE_ANALYTICS,
} from "@/env";

// Initialize Firebase
const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_PROJECT_ID + ".firebaseapp.com",
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_PROJECT_ID + ".appspot.com",
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: `G-${GOOGLE_ANALYTICS}`,
};

// Initialize Firebase
let fire: firebase.app.App;
if (!firebase.apps.length) {
  fire = firebase.initializeApp(firebaseConfig);
} else {
  fire = firebase.app(); // if already initialized, use that one
}

export const GoogleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const auth = fire.auth();
export const firestore = fire.firestore();
export const storage = fire.storage();
export const analytics = firebase.analytics;
export const appCheck = fire.appCheck();

export default fire;
