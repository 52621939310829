import * as firebaseClient from "@/firebase";
import * as Sentry from "@sentry/nextjs";
import { Session } from "@/models/session";
import moment from "moment";
import { SurveyResponse } from "@/models/survey";
import getSurvey from "./getSurvey";
import getSession from "../session/getSession";
import getVersion from "../version/getVersion";
import getSessionById from "../session/getSessionById";

// starts session and returns session id
const startSurvey = async (data: {
  orgId: string;
  programId: string;
  userId: string;
  surveyId: string;
  sessionId: string;
  embeddedSessionId?: string;
  embeddedStepId?: string;
}): Promise<SurveyResponse> => {
  try {
    const {
      programId,
      userId,
      surveyId,
      orgId,
      sessionId,
      embeddedSessionId,
      embeddedStepId,
    } = data;

    console.log("SHOULD START SURVEY", data);

    // try to get existing survey
    const existingSurvey = await getSurvey({
      userId,
      surveyId,
      sessionId,
      embeddedSessionId,
      embeddedStepId,
    });
    if (existingSurvey) {
      return existingSurvey;
    }
    console.log("NO EXISTING SURVEY");

    const session = await getSessionById(sessionId)();
    console.log("SESSION", session);

    const { minorVersion, majorVersion } = await getVersion(orgId)();

    let survey: SurveyResponse = {
      id: "PENDING",
      userId,
      orgId,
      programId,
      minorVersion,
      majorVersion,
      surveyId,
      sessionId,
      startedTime: moment().unix(),
    };
    if (embeddedSessionId) survey.embeddedSessionId = embeddedSessionId;
    if (embeddedStepId) survey.embeddedStepId = embeddedStepId;
    if (session?.groupId) survey.groupId = session.groupId;

    console.log("SHOULD ADD SURVEY", survey);
    const surveyDoc = await firebaseClient.firestore
      .collection("surveys")
      .add(survey);

    await surveyDoc.update({ id: surveyDoc.id });
    console.log("ADDED SURVEY", surveyDoc.id);

    return {
      ...survey,
      id: surveyDoc.id,
    } as SurveyResponse;
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

export default startSurvey;
