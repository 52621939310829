import { Org } from "@/models/org";
import { Owner } from "@/models/owner";
import { User } from "@/models/user";
import { colors } from "./styles";

export const ownerCoverImage = (owner: Owner): string => {
  try {
    return (owner as Org).coverImage || null;
  } catch (e) {
    return null;
  }
};

export const ownerType = (owner: Owner): "org" | "user" => {
  try {
    return typeof (owner as Org).title !== "undefined" ? "org" : "user";
  } catch (e) {
    return null;
  }
};

export const ownerCollection = (owner: Owner): "orgs" | "users" => {
  try {
    return typeof (owner as Org).title !== "undefined" ? "orgs" : "users";
  } catch (e) {
    return null;
  }
};

export const ownerSlug = (owner: Owner): string => {
  try {
    return owner?.slug || owner.id;
  } catch (e) {
    return null;
  }
};

export const ownerTitle = (owner: Owner): string => {
  try {
    return (
      (owner as Org)?.title ||
      (owner as User)?.name ||
      (owner as User)?.email ||
      null
    );
  } catch (e) {
    return null;
  }
};

export const ownerDescription = (owner: Owner): string => {
  try {
    return (owner as Org)?.description || null;
  } catch (e) {
    return null;
  }
};

export const ownerFavicon = (owner: Owner): string => {
  try {
    return (owner as Org)?.favicon || null;
  } catch (e) {
    return null;
  }
};

export const ownerContactEmail = (owner: Owner): string => {
  try {
    return ownerType(owner) === "org"
      ? (owner as Org).contactEmail
      : (owner as User).email || null;
  } catch (e) {
    return null;
  }
};

export const ownerImage = (owner: Owner): string => {
  try {
    return owner.image || null;
  } catch (e) {
    return null;
  }
};

export const ownerColor = (owner: Owner): string => {
  try {
    return (owner as Org)?.color || colors.primary;
  } catch (e) {
    return null;
  }
};

export const ownerAdmin = (
  owner: Owner,
  uid: string,
  userEmail?: string
): boolean => {
  try {
    if ((owner as Org)?.admins?.includes(uid)) return true;
    if (userEmail && (owner as Org)?.admins?.includes(userEmail)) return true;
    if (owner?.id === uid) return true;
    return false;
  } catch (e) {
    return null;
  }
};

export const ownerDomain = (owner: Owner, pretty?: boolean): string => {
  try {
    const prettyPrefix = pretty ? "" : "https://";
    if ((owner as Org)?.domain) {
      return `${prettyPrefix}${(owner as Org).domain}`;
    }

    if ((owner as Org)?.slug) {
      return `${prettyPrefix}${(owner as Org).slug}.steppable.com`;
    }

    return `${prettyPrefix}steppable.com/${owner.id}`;
  } catch (e) {
    return null;
  }
};
