import React, { FC, useEffect, useRef } from "react";
import { AppProps } from "next/app";
import { wrapper } from "../redux/store";
import { createGlobalStyle } from "styled-components";
import { Provider, ReactReduxContext } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { Hydrate } from "react-query/hydration";
import * as Sentry from "@sentry/nextjs";
import { appCheck } from "@/firebase";
import Head from "next/head";
import { defaults } from "react-chartjs-2";
import { normalize } from "styled-normalize";
import { CAPTCHA_KEY, POSTHOG_KEY } from "@/env";
import posthog from "posthog-js";
import { colors } from "@/lib/styles";
import { Analytics } from "@vercel/analytics/react";

defaults.font.family = "Poppins";
defaults.font.size = 12;

const GlobalStyle = createGlobalStyle`
  ${normalize}
  html { font-family: 'Poppins', sans-serif;
}
  body {
    background: "#072132";
  }
  * {
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
	}
  *::-webkit-scrollbar-track {
    background-color: ${colors.nearWhite};
  }
  *::-webkit-scrollbar {
    width: 6px;
		height: 6px;
		border-radius: 3px;
    background-color: ${colors.nearWhite};
  }
  *::-webkit-scrollbar-thumb {
		border-radius: 3px;
    background-color: ${colors.gray};
  }
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
  .cover {
    object-fit: cover;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
  .contain {
    object-fit: contain;
    object-position: left;
  }
`;

const App: FC<AppProps> = ({ Component, pageProps }) => {
  useEffect(() => {
    try {
      if (!window.location.href.includes("localhost")) {
        posthog.init(POSTHOG_KEY, {
          api_host: "https://app.posthog.com",
        });
      }
      appCheck.activate(CAPTCHA_KEY, true);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  const queryClientRef = useRef<QueryClient>();
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=0"
        />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#0079FF" />
        <meta name="msapplication-TileColor" content="#fbfbfb" />
        <meta name="theme-color" content="#ffffff" />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Italianno&display=swap"
          rel="stylesheet"
        ></link>
        {typeof window !== "undefined" && (
          <script type="text/javascript">
            {`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
          heap.load(${
            !["steppable.vercel.app", "localhost"].includes(
              window.location.hostname
            ) && process.env.NODE_ENV === "production"
              ? "2100646306"
              : "3358719598"
          });`}
            {!window.location.href.includes("localhost") &&
            process.env.NODE_ENV === "production"
              ? `!function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
    posthog.init('${POSTHOG_KEY}',{api_host:'https://app.posthog.com'})`
              : null}
          </script>
        )}
      </Head>
      <GlobalStyle />
      <ReactReduxContext.Consumer>
        {({ store }) => (
          <Provider store={store}>
            <QueryClientProvider client={queryClientRef.current}>
              <Hydrate state={pageProps.dehydratedState}>
                {/* @ts-ignore */}
                <Component {...pageProps} />
                <Analytics />
              </Hydrate>
            </QueryClientProvider>
          </Provider>
        )}
      </ReactReduxContext.Consumer>
    </>
  );
};

export default wrapper.withRedux(App);
