export const colors = {
  primary: "#1982C4",
  nearWhite: "#F4F4F4",
  nearWhitePrimary: "#E7F7EE",
  lightGray: "#E5E5E5",
  gray: "#B6B6B6",
  darkGray: "#6D7176",
  nearBlack: "#072132",
  white: "#FFFFFF",
  red: "#FF595E",
  lightRed: "#F8C8C7",
  yellow: "#FFCA3A",
  lightYellow: "#F9F1C4",
  green: "#8AC926",
  lightGreen: "#D0E9D1",
  blue: "#1982C4",
  purple: "#6A4C93",
};

export const primaryColors = [
  colors.red,
  colors.yellow,
  colors.green,
  colors.blue,
  colors.purple,
];

export const optionColors: { name: string; title: string; color: string }[] = [
  { name: "black", title: "Black", color: colors.nearBlack },
  { name: "purple", title: "Purple", color: colors.purple },
  { name: "blue", title: "Blue", color: colors.blue },
  { name: "green", title: "Green", color: colors.green },
  { name: "yellow", title: "Yellow", color: colors.yellow },
  { name: "red", title: "Red", color: colors.red },
];
