import { firestore } from "@/firebase";
import { Session } from "@/models/session";
import * as Sentry from "@sentry/nextjs";

const getSession =
  (data: {
    userId: string;
    programId: string;
    embeddedSessionId?: string;
    embeddedStepId?: string;
  }) =>
  async (): Promise<Session | null> => {
    const { programId, userId, embeddedSessionId, embeddedStepId } = data;
    try {
      console.log("SHOULD GET SESSION", data);
      // see if session already exists
      let q = firestore
        .collection("sessions")
        .where("programId", "==", programId)
        .where("userId", "==", userId);
      if (embeddedSessionId)
        q = q.where("embeddedSessionId", "==", embeddedSessionId);
      if (embeddedStepId) q = q.where("embeddedStepId", "==", embeddedStepId);

      const sessionQuery = await q.orderBy("startedTime", "desc").get();

      if (!sessionQuery.empty) return sessionQuery.docs[0].data() as Session;
      else return null;
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

export default getSession;
