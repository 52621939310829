import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "@/redux";
import { User } from "@/models/user";
import { createSelector } from "reselect";
import * as Sentry from "@sentry/nextjs";
import { firestore } from "@/firebase";
import { authSelectors } from "./auth";
import claimInvites from "@/db/user/claimInvites";
import heap from "@/lib/heap";
import { Owner } from "@/models/owner";

const WATCH_USER_INIT = "user/WATCH_USER_INIT";
const WATCH_USER_SUCCESS = "user/WATCH_USER_SUCCESS";
const WATCH_USER_FAILURE = "user/WATCH_USER_FAILURE";
const RESET_USER = "user/RESET_USER";

const userState = {
  user: null as User | null,
  isWatching: false as boolean,
  error: null as Error | null,
};

export default function reducer(
  state = userState,
  action: UserAction
): typeof userState {
  switch (action.type) {
    case WATCH_USER_INIT:
      return {
        ...state,
        isWatching: false,
        error: null,
      };
    case WATCH_USER_SUCCESS:
      return {
        ...state,
        isWatching: true,
        user: action.user,
      };
    case WATCH_USER_FAILURE:
      return {
        ...state,
        isWatching: false,
        error: action.error,
      };
    case RESET_USER:
      return userState;
    default:
      return state;
  }
}

export const watchUserInit = (): { type: typeof WATCH_USER_INIT } => {
  return { type: WATCH_USER_INIT };
};

export const watchUserSuccess = (
  user: User
): { type: typeof WATCH_USER_SUCCESS; user: User } => {
  return { type: WATCH_USER_SUCCESS, user };
};

export const watchUserFailure = (
  error: Error
): { type: typeof WATCH_USER_FAILURE; error: Error } => {
  return { type: WATCH_USER_FAILURE, error };
};

export const resetUser = (): { type: typeof RESET_USER } => {
  return { type: RESET_USER };
};

export const WatchUser =
  (owner: Owner): ThunkAction<() => void, RootState, null, Action<string>> =>
  (dispatch, getState) => {
    try {
      dispatch(watchUserInit());

      const state = getState();
      const cachedUser = userSelectors.user(state);

      const uid = authSelectors.uid(state);
      if (!uid) throw new Error("No uid to watch");

      return firestore
        .collection("users")
        .doc(uid)
        .onSnapshot(
          async (snap) => {
            const user = snap.data() as User;
            if (user) {
              heap.syncUser(user);
              dispatch(watchUserSuccess(user));
              // if logging in or signing up -> claim invites
              if (!cachedUser)
                await claimInvites({ userId: uid, userEmail: user.email });
            }
          },
          (e) => {
            throw e;
          }
        );
    } catch (error) {
      dispatch(watchUserFailure(error));
      Sentry.captureException(error);

      return () => {};
    }
  };

type UserAction =
  | ReturnType<typeof watchUserInit>
  | ReturnType<typeof watchUserSuccess>
  | ReturnType<typeof watchUserFailure>
  | ReturnType<typeof resetUser>;

const errorSyncing = (state: RootState) => state.user.error;
const user = (state: RootState) => state.user.user;
const userName = createSelector(user, (userVal) =>
  userVal ? userVal.name : null
);
const userEmail = createSelector(user, (userVal) =>
  userVal ? userVal.email : null
);
const userImage = createSelector(user, (userVal) =>
  userVal ? userVal.image : null
);
const userImagePath = createSelector(user, (userVal) =>
  userVal ? userVal.imagePath : null
);

const userOwnerships = createSelector(user, (userVal) => {
  if (!userVal || !userVal.ownerships) return null;
  return userVal.ownerships;
});
const userMemberships = createSelector(user, (userVal) => {
  if (!userVal || !userVal.memberships) return null;
  return userVal.memberships;
});

export const userSelectors = {
  user,
  errorSyncing,
  userName,
  userEmail,
  userImage,
  userImagePath,
  userOwnerships,
  userMemberships,
};
