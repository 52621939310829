import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "@/redux/index";
import { auth, firestore, GoogleAuthProvider } from "@/firebase";
import * as Sentry from "@sentry/nextjs";
import moment from "moment";
import { User } from "@/models/user";
import { SurveyResponse } from "@/models/survey";
import { Program, SurveyStep } from "@/models/program";
import {
  formValueSelector,
  initialize,
  reset,
  SubmissionError,
} from "redux-form";
import completeSurvey from "@/db/survey/completeSurvey";
import { find } from "lodash";
import { authSelectors } from "./auth";

const SURVEY_INIT = "auth/SURVEY_INIT";
const SURVEY_SUCCESS = "auth/SURVEY_SUCCESS";
const SURVEY_FAILURE = "auth/SURVEY_FAILURE";
const RESET_SURVEY = "auth/RESET_SURVEY";

const surveyState = {
  uid: null as string | null,
  isSurveying: false as boolean,
  errorSurveying: null as Error | null,
};

export default function reducer(
  state = surveyState,
  action: SurveyAction
): typeof surveyState {
  switch (action.type) {
    case SURVEY_INIT:
      return {
        ...state,
        isSurveying: true,
      };

    case SURVEY_SUCCESS:
      return {
        ...state,
        isSurveying: false,
      };

    case SURVEY_FAILURE:
      return {
        ...state,
        isSurveying: false,
        errorSurveying: action.error,
      };
    case RESET_SURVEY:
      return surveyState;
    default:
      return state;
  }
}

export const surveyInit = (): { type: typeof SURVEY_INIT } => {
  return { type: SURVEY_INIT };
};

export const surveySuccess = (): {
  type: typeof SURVEY_SUCCESS;
} => {
  return { type: SURVEY_SUCCESS };
};

export const surveyFailure = (
  error: Error
): { type: typeof SURVEY_FAILURE; error: Error } => {
  return { type: SURVEY_FAILURE, error };
};

export const resetSurvey = (): { type: typeof RESET_SURVEY } => {
  return { type: RESET_SURVEY };
};

export const Survey =
  (
    program: Program,
    step: SurveyStep,
    groupId: string | undefined,
    sessionId: string,
    embeddedStepId?: string
  ): ThunkAction<Promise<void>, RootState, null, Action<string>> =>
  async (dispatch, getState) => {
    try {
      dispatch(surveyInit());
      const state = getState();

      const userId = authSelectors.uid(state);
      console.log("USERID", userId);

      // get all form values
      let questions = formValueSelector(
        `program-${program.id}-survey-${step.surveyId}-session-${sessionId}-${
          embeddedStepId || "normal"
        }`
      )(state, "questions");

      if (!questions) questions = {};

      // make sure there is an answer for every value
      for (const stepQuestion of step.questions) {
        switch (stepQuestion.type) {
          case "MULTIPLE_CHOICE":
            if (
              !(
                questions[stepQuestion.questionId] &&
                (typeof questions[stepQuestion.questionId] === "string" ||
                  questions[stepQuestion.questionId].length > 0)
              )
            ) {
              throw new SubmissionError({
                questions: {
                  [stepQuestion.questionId]: stepQuestion.selectMultiple
                    ? "Please select all that apply"
                    : "Please select your response",
                } as any,
              });
            }
            break;
          case "SHORT_ANSWER":
            if (!questions[stepQuestion.questionId])
              throw new SubmissionError({
                questions: {
                  [stepQuestion.questionId]: "Please add a response",
                } as any,
              });
            break;
          case "RANGE":
            if (typeof questions[stepQuestion.questionId] !== "number")
              throw new SubmissionError({
                questions: {
                  [stepQuestion.questionId]: "Please add a response",
                } as any,
              });
            break;
        }
      }

      await completeSurvey({
        orgId: program.orgId,
        surveyId: step.surveyId,
        questions,
        userId,
        sessionId,
        programId: program.id,
        embeddedStepId,
      }).catch((e) => {
        console.log(e);
        throw new SubmissionError({ _error: "Could not connect to api" });
      });

      dispatch(surveySuccess());

      // reset form
      const formId = `program-${program.id}-survey-${step.surveyId}-session-${sessionId}`;
      dispatch(reset(formId));
      dispatch(initialize(formId, { questions } ? { questions } : {}));

      // if quiz, submit form but give explanation for each wrong answer
      if (step.surveyType === "QUIZ") {
        const submissionErrors: any = {};
        for (const stepQuestion of step.questions) {
          switch (stepQuestion.type) {
            case "MULTIPLE_CHOICE":
              if (stepQuestion.selectMultiple) {
                const feedback: string[] = [];
                for (const option of stepQuestion.options) {
                  if (
                    option.correct &&
                    !questions[stepQuestion.questionId].includes(
                      option.optionId
                    )
                  ) {
                    if (option.explanation) feedback.push(option.explanation);
                  } else if (
                    !option.correct &&
                    questions[stepQuestion.questionId].includes(option.optionId)
                  ) {
                    if (option.explanation) feedback.push(option.explanation);
                  }
                }
                if (feedback.length > 0)
                  submissionErrors[stepQuestion.questionId] =
                    feedback.join(", ");
              } else {
                const correctOption = find(stepQuestion.options, {
                  correct: true,
                });
                const selectedOption = find(stepQuestion.options, {
                  optionId: questions[stepQuestion.questionId],
                });
                if (!correctOption || !selectedOption) continue;
                if (selectedOption.optionId !== correctOption.optionId)
                  if (selectedOption.explanation)
                    submissionErrors[stepQuestion.questionId] =
                      selectedOption.explanation;
              }
              break;
          }
        }
        // throw submission error no matter what to trigger feedback on wrong and right questions
        throw new SubmissionError({
          questions: submissionErrors,
          validated: "yes",
        });
      }
    } catch (error) {
      dispatch(surveyFailure(error));
      console.log(error);
      throw error;
    }
  };

type SurveyAction =
  | ReturnType<typeof surveyInit>
  | ReturnType<typeof surveySuccess>
  | ReturnType<typeof surveyFailure>
  | ReturnType<typeof resetSurvey>;

const isSurveying = (state: RootState) => state.survey.isSurveying;
const errorSurveying = (state: RootState) => state.survey.errorSurveying;
const uid = (state: RootState) => state.survey.uid;
const loggedIn = (state: RootState) => Boolean(state.survey.uid);

export const surveySelectors = {
  isSurveying,
  errorSurveying,
  uid,
  loggedIn,
};
