import { firestore } from "@/firebase";
import { ownerSlug, ownerType } from "@/lib/owner";
import { Folder } from "@/models/folder";
import { Owner } from "@/models/owner";
import { Program } from "@/models/program";
import { FolderVersion } from "@/models/version";

const getFolder =
  (owner: Owner, folderId: string, admin?: boolean) =>
  async (): Promise<Folder> => {
    if (!folderId || !owner) return null;
    if (!admin) {
      const latestFolderDoc = await firestore
        .collection(ownerType(owner) === "org" ? "orgs" : "users")
        .doc(owner.id)
        .collection("folders")
        .doc(folderId)
        .collection("versions")
        .doc("latest")
        .get();
      if (latestFolderDoc.exists) {
        const folderVersion = latestFolderDoc.data() as FolderVersion;
        return folderVersion.folder;
      }
    }
    const folderDoc = await firestore
      .collection(ownerType(owner) === "org" ? "orgs" : "users")
      .doc(owner.id)
      .collection("folders")
      .doc(folderId)
      .get();
    return folderDoc.data() as Folder;
  };

export default getFolder;
