import { createStore, applyMiddleware, Action } from "redux";
import { MakeStore, createWrapper, HYDRATE, Context } from "next-redux-wrapper";
import Router from "next/router";
import withRedux, {
  createRouterMiddleware,
  initialRouterState,
} from "connected-next-router";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { combinedReducer, RootState } from "@/redux/index";
import { composeWithDevTools } from "redux-devtools-extension";

// Using next-redux-wrapper's initStore
const reducer = (state, action) => {
  return combinedReducer(state, action);
};

// create a makeStore function
export const makeStore: MakeStore<RootState> = (context) => {
  let store;
  const routerMiddleware = createRouterMiddleware();
  const { asPath } = (context as any).ctx || Router.router || {};
  let initialState;
  if (asPath) {
    initialState = {
      router: initialRouterState(asPath),
    };
  }

  store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(routerMiddleware, thunk))
  );

  return store;
};

// export an assembled wrapper
export const wrapper = createWrapper(makeStore, { debug: true });
