import { firestore } from "@/firebase";
import firebase from "firebase";
import * as Sentry from "@sentry/nextjs";
import { User } from "@/models/user";
import getUser from "./getUser";
import getOrg from "../org/getOrg";
import getGroup from "../group/getGroup";
import { ownerPath } from "@/lib/path";

/*
	workspaces are prioritized in their array order
	
	group workspace: "ORG_ID/GROUP_ID"
	org workspace: "ORG_ID"
*/

const getPrimaryWorkspace = async (data: {
  uid: string;
  userData?: User;
}): Promise<string> => {
  try {
    const { userData, uid } = data;
    const user = userData || (await getUser(uid)());
    const ownerships = user?.ownerships;
    const ownership = ownerships?.[0];
    if (!ownership) return null;
    let orgId: string;
    let groupId: string;
    if (ownership.includes("/")) {
      const [org, group] = ownership.split("/");
      orgId = org;
      groupId = group;
    } else orgId = ownership;
    const org = await getOrg(orgId)();
    if (org && org?.slug) return ownerPath(org, "dashboard", null);
    else return;
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

export default getPrimaryWorkspace;
