import { firestore } from "@/firebase";
import firebase from "firebase";
import * as Sentry from "@sentry/nextjs";
import { Group } from "@/models/group";
import toast from "react-hot-toast";
import { Org } from "@/models/org";
import { Invite } from "@/models/invite";

const claimInvites = async (data: {
  userId: string;
  userEmail: string;
}): Promise<void> => {
  try {
    const { userId, userEmail } = data;

    const userRef = firestore.collection("users").doc(userId);

    // if invited to manage organization or group by email, convert email to uid and add ownership
    const orgQuery = await firestore
      .collection("orgs")
      .where("admins", "array-contains", userEmail)
      .get();
    for (const orgDoc of orgQuery.docs) {
      const org = orgDoc.data() as Org;
      // add userId to org admins
      await orgDoc.ref.update({
        admins: firebase.firestore.FieldValue.arrayUnion(userId),
      });
      // remove userEmail from org admins
      await orgDoc.ref.update({
        admins: firebase.firestore.FieldValue.arrayRemove(userEmail),
      });
      // add orgId to user's ownerships
      await userRef.update({
        ownerships: firebase.firestore.FieldValue.arrayUnion(org.id),
      });
      toast.success(`Successfully joined ${org.title}`);
    }
    const groupQuery = await firestore
      .collectionGroup("groups")
      .where("admins", "array-contains", userEmail)
      .get();
    for (const groupDoc of groupQuery.docs) {
      const group = groupDoc.data() as Group;
      // add userId to group admins
      await groupDoc.ref.update({
        admins: firebase.firestore.FieldValue.arrayUnion(userId),
      });
      // remove userEmail from group admins
      await groupDoc.ref.update({
        admins: firebase.firestore.FieldValue.arrayRemove(userEmail),
      });
      // add groupId to user's ownerships
      await userRef.update({
        ownerships: firebase.firestore.FieldValue.arrayUnion(
          `${group?.orgId}/${group?.id}`
        ),
      });
      toast.success(`Successfully joined ${group?.title}`);
    }

    // if invited to be a member of a group by email, convert email to uid and add membership
    const inviteQuery = await firestore
      .collectionGroup("invites")
      .where("email", "==", userEmail)
      .get();
    for (const inviteDoc of inviteQuery.docs) {
      const invite = inviteDoc.data() as Invite;
      // add membership
      await userRef.update({
        memberships: firebase.firestore.FieldValue.arrayUnion(
          `${invite.orgId}/${invite.groupId}`
        ),
      });
      await userRef.update({
        memberships: firebase.firestore.FieldValue.arrayUnion(
          `${invite.orgId}/${invite.groupId}/${invite.subgroupId}`
        ),
      });

      // delete invite
      await inviteDoc.ref.delete();

      // get group
      const groupDoc = await firestore
        .collection("orgs")
        .doc(invite.orgId)
        .collection("groups")
        .doc(invite.groupId)
        .get();
      const group = groupDoc.data() as Group;

      toast.success(`Successfully joined ${group?.title}`);
    }
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

export default claimInvites;
