import * as firebaseClient from "@/firebase";
import moment from "moment";
import * as Sentry from "@sentry/nextjs";
import { SurveyResponse } from "@/models/survey";
import getProgram from "../program/getSlugProgram";
import { Program, SurveyStep } from "@/models/program";
import { find } from "lodash";
import getSurvey from "./getSurvey";
import startSurvey from "./startSurvey";
import getSession from "../session/getSession";
import getSessionById from "../session/getSessionById";

// starts a survey step
const completeSurvey = async (data: {
  orgId: string;
  userId: string;
  sessionId: string;
  programId: string;
  surveyId: string;
  questions: SurveyResponse["questions"];
  embeddedStepId?: string;
}): Promise<void> => {
  const {
    orgId,
    programId,
    surveyId,
    questions,
    sessionId,
    userId,
    embeddedStepId,
  } = data;
  try {
    let session = embeddedStepId
      ? await getSession({
          userId,
          programId,
          embeddedSessionId: sessionId,
          embeddedStepId: embeddedStepId,
        })()
      : await getSessionById(sessionId)();
    console.log("COMPLETE SURVEY > SESSION", session);
    const survey = await startSurvey({
      orgId,
      surveyId,
      sessionId,
      userId,
      programId,
      embeddedSessionId: session.embeddedSessionId,
      embeddedStepId: session.embeddedStepId,
    });
    if (survey) {
      // get survey step questions
      const programDoc = await firebaseClient.firestore
        .collection("programs")
        .doc(programId)
        .get();
      const program = programDoc.data() as Program;
      const surveyStep = find(program.steps, {
        type: "SURVEY",
        surveyId,
      }) as SurveyStep;
      if (!surveyStep) throw new Error("Cannot retrieve survey step");

      const surveyUpdate: Partial<SurveyResponse> = {
        questions,
        completedTime: moment().unix(),
      };

      await firebaseClient.firestore
        .collection("surveys")
        .doc(survey.id)
        .update(surveyUpdate);
    } else throw new Error("Could not retrieve survey");
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
  }
};

export default completeSurvey;
