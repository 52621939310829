import { firestore } from "@/firebase";
import { OrgVersion } from "@/models/version";
import * as Sentry from "@sentry/nextjs";

const getVersion =
  (orgId: string, latest?: boolean) =>
  async (): Promise<{
    majorVersion: number;
    minorVersion: number;
    latest?: boolean;
    explicit?: boolean;
  }> => {
    try {
      if (orgId) {
        // default to latest version
        const latestOrgVersionDoc = await firestore
          .collection("orgs")
          .doc(orgId)
          .collection("versions")
          .doc("latest")
          .get();
        if (!latestOrgVersionDoc.exists) return null;
        const latestOrgVersion = latestOrgVersionDoc.data() as OrgVersion;

        if (typeof window !== "undefined" && !latest) {
          const explicitVersion = new URLSearchParams(
            window.location.search
          ).get("version");

          // if version passed in query string, use that
          if (explicitVersion && latestOrgVersion) {
            const [majorVersion, minorVersion] = explicitVersion.split(".");
            return {
              majorVersion: Number(majorVersion),
              minorVersion: Number(minorVersion),
              latest:
                Number(majorVersion) == latestOrgVersion?.majorVersion &&
                Number(minorVersion) == latestOrgVersion?.minorVersion,
              explicit: true,
            };
          }
        }
        if (latestOrgVersion) {
          return {
            majorVersion: latestOrgVersion?.majorVersion,
            minorVersion: latestOrgVersion?.minorVersion,
            latest: true,
          };
        } else return null;
      } else return null;
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      return null;
    }
  };

export default getVersion;
